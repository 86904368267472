.NavbarItems {
    background: black;
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: left;
    margin-right: 2rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #484848;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

.nav-title {
    display: none;
}

@media screen and (max-width: 550px) {
    .NavbarItems {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 50px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-title {
        display: block;
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
    }

    .nav-menu.active {
        background: black;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #484848;
        border-radius: 0;
        cursor: pointer;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 25%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
}