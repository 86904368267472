.projects {
    .image {
        opacity: 0.8;
        justify-content: center;
    }

    .text-container {
        height: 35vh;
    }

    .text {
        background: black;
        left: 50%;
        top: 50%;
        color: white;
        position: absolute;
        padding: 20px;
        font-size: 40px;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }

    .list-container {
        padding-top: 20px;
        padding-bottom: 20px;

        .body-container {
            display: flex;
            padding-top: 10px;
            padding-bottom: 10px;
    
            .body-image-container {
                padding: 10px;
                width: 50%;
                text-align: center;
    
                .body-image {
                    width: 100%;
                    border: 1px solid black;
                }
            }
    
            .body-text-container {
                padding: 10px;
                text-align: left;
                width: 50%;
    
                .body-text {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .projects {

        .list-container {
            padding-top: 20px;
            padding-bottom: 20px;

            .body-container {
                display: flex;
                flex-direction: column;
                padding-top: 20px;
        
                .body-image-container {
                    padding: 10px;
                    width: 100%;
                    text-align: center;
        
                    .body-image {
                        width: 100%;
                        border: 1px solid black;
                    }
                }
        
                .body-text-container {
                    padding: 10px;
                    text-align: left;
                    width: 100%;
    
                    .body-title {
                        font-size: 24px !important;
                    }
        
                    .body-text {
                        width: 100%;
                    }
                }
            }
        }
    }
}