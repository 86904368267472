.about {
    background: #fff;
    padding: 30px;

    .title {
        text-align: center;
        font-size: 30px;
    }

    .intro {
        text-align: left;
        padding-top: 10px;
        font-size: 20px;
    }

    .container {
        padding-top: 50px;
        display: flex;

        .image {
            border-radius: 22%;
            max-width: 50%;
        }

        .image-container {
            width: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .body-container {
            width: 50%;
            text-align: left;
            font-size: 20px;
        }
        .contact-container {
            display: flex;
            flex-direction: column;

            div {
                display: flex;
                height: 30px;
                margin-bottom: 5px;
                align-items: center;

                img {
                    width: 30px;
                    margin-right: 5px;
                }

                a {
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .container {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .image {
            border-radius: 50%;
            max-width: 100%;
        }
    
        .image-container {
            width: 100%;
            text-align: center;
        }
    
        .body-container {
            width: 100% !important;
            text-align: left;
            font-size: 20px;
            padding-top: 20px;
        }
    }
}